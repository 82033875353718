/* eslint-disable */

// project-imports
import applications from './applications';
import widget from './widget';
import formsTables from './forms-tables';
import chartsMap from './charts-map';
import support from './support';
import pages from './pages';
import gening from './gening';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [gening]
};

export default menuItems;
