/* eslint-disable */

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Story, Fatrows, PresentionChart, NoteText, Profile2User, TrendUp, Location, FavoriteChart } from 'iconsax-react';
import { Tooltip } from '@mui/material';


// ...other code


// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'group-widget',
  title: 'XGreening',
  icons: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'statistics',
      title: '主页',
      type: 'item',
      url: '/dashboard/first',
      gIcon: '首页.png',
      icon: Story,
      // icon: <span title="主页"><Story /></span>,
    },
    {
      id: 'data',
      title: '时序曲线',
      type: 'item',
      url: '/dashboard/second',
      gIcon: '时序曲线.png',
      icon: TrendUp
    },
    {
      id: 'site',
      title: '站点信息',
      type: 'item',
      url: '/dashboard/third',
      gIcon: '智慧城市.png',
      icon: Location
    },
    {
      id: 'market',
      title: '电力市场',
      type: 'item',
      url: '/dashboard/PowerMarket',
      gIcon: '电网.png',
      icon: FavoriteChart
    },
    // {
    //   id: 'chart',
    //   title: '系统日志',
    //   type: 'item',
    //   url: '/widget/chart',
    //   icon: DocumentUpload
    // },
    {
      id: 'chart',
      title: '用户档案',
      type: 'item',
      url: '/dashboard/sixth',
      gIcon: '用户.png',
      icon: Profile2User
    },
    {
      id: 'log',
      title: '系统日志',
      type: 'item',
      url: '/dashboard/SysLog',
      gIcon: '07-消息.png',
      icon: NoteText
    },
    // {
    //     id: 'chart',
    //     title: '系统日志',
    //     type: 'item',
    //     url: '/widget/chart',
    //     icon: DocumentUpload
    //   },
      // {
      //   id: 'chart',
      //   title: '用户档案',
      //   type: 'item',
      //   url: '/widget/chart',
      //   icon: NoteText
      // }
  ]
};

export default widget;
