import { useTheme } from '@mui/material/styles';
import logoIconDark from 'assets/images/logo-light.png'; // 暗模式图标
import logoIcon from 'assets/images/logo-light.png'; // 亮模式图标

const LogoMain = () => {
  const theme = useTheme();
  const currentMode = theme.palette.mode; // 获取当前主题模式

  // 根据当前主题模式选择图标
  const logoSrc = currentMode === 'dark' ? logoIconDark : logoIcon;

  return (
    <img src={logoSrc} alt="icon logo" width="70" />
  );
};

export default LogoMain;